import React from "react";

import { FeatureGalleryItem } from "../../../components/_V2/FeaturesGallery";
import { StaticImage } from "gatsby-plugin-image";
import { pagesLinks } from "../../../pages-data/_V2/common/links";

// @ts-ignore
import ChevronRight from "../../../assets/svg/chevronRight.svg";
import mediaContent from "./mediaContent.module.scss";
import styles from "./styles.module.scss";
import { Locales } from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return {
			features: [
				{
					desktopContentWidth: 500,
					tabName: "Зоны доставки",
					desktopTitle: "Расчёт стоимости доставки",
					tabletTitle: "Расчёт стоимости доставки",
					mobileTitle: "Расчёт стоимости доставки",
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Включи автоматический расчёт стоимости доставки по&nbsp;зонам города или поставь фиксированную стоимость
							для всех заказов.
						</p>
					),
					tabletContent: (
						<>
							Включи автоматический расчёт стоимости доставки по&nbsp;зонам города или поставь фиксированную стоимость
							для&nbsp;всех заказов.
						</>
					),
					mobileContent: (
						<>
							Можно включить автоматический расчёт стоимости доставки по зонам&nbsp;города или поставить фиксированную
							стоимость для всех заказов.
						</>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image1}
							src="./assets/delivery-feature-1_kz.webp"
							alt={""}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
				{
					desktopContentWidth: 500,
					tabName: "Меню",
					desktopTitle: "Синхронизация позиций",
					tabletTitle: "Синхронизация позиций",
					mobileTitle: "Синхронизация позиций",
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Автоматическое обновление во&nbsp;всех сервисах. Актуальное&nbsp;меню с&nbsp;акциями и&nbsp;доступными
							позициями в&nbsp;приложении, агрегаторах и&nbsp;на&nbsp;сайте.
						</p>
					),
					tabletContent: (
						<p className={mediaContent.smallDescription}>
							Автоматическое обновление во&nbsp;всех сервисах. Актуальное&nbsp;меню с&nbsp;акциями и&nbsp;доступными
							позициями в&nbsp;приложении, агрегаторах и&nbsp;на&nbsp;сайте.
						</p>
					),
					mobileContent: (
						<p className={mediaContent.smallDescription}>
							Автоматическое обновление во всех&nbsp;сервисах. Актуальное меню
							с&nbsp;акциями и&nbsp;доступными позициями в&nbsp;приложении, агрегаторах и&nbsp;на&nbsp;сайте.
						</p>
					),
					mediaContent: (
						<>
							<StaticImage
								className={mediaContent.image3}
								src="./assets/delivery-feature-3_kz.webp"
								alt={""}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		};
	}

	if (locale === "kz-KZ") {
		return {
			features: [
				{
					desktopContentWidth: 500,
					tabName: "Жеткізу аймақтары",
					desktopTitle: "Жеткізу құнын есептеу",
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Қала аймақтары бойынша жеткізу құнын автоматты түрде есептеуді қосыңыз нем есе барлық тапсырыстар үшін бекітілген құнды қойыңыз.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image1}
							src="./assets/delivery-feature-1_kz.webp"
							alt={""}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
				{
					desktopContentWidth: 500,
					tabName: "Мәзір",
					desktopTitle: "Позицияларды синхрондау",
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Барлық сервистерде автоматты түрде жаңарту. Қосымшада, агрегаторларды және сайтта акциялары мен қолжетімді позициялары бар өзекті мәзір.
						</p>
					),
					mediaContent: (
						<>
							<StaticImage
								className={mediaContent.image3}
								src="./assets/delivery-feature-3_kz.webp"
								alt={""}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		};
	}

	if (locale === "ru-BY") {
		return {
			features: [
				{
					desktopContentWidth: 500,
					tabName: "Зоны доставки",
					desktopTitle: "Расчёт стоимости доставки",
					tabletTitle: "Расчёт стоимости доставки",
					mobileTitle: "Расчёт стоимости доставки",
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Можно включить автоматический расчёт стоимости доставки по&nbsp;зонам города или поставить фиксированную
							стоимость для всех заказов.
						</p>
					),
					tabletContent: (
						<p className={mediaContent.smallDescription}>
							Можно включить автоматический расчёт стоимости доставки по&nbsp;зонам города или поставить фиксированную
							стоимость для всех заказов.
						</p>
					),
					mobileContent: (
						<p className={mediaContent.smallDescription}>
							Можно включить автоматический расчёт стоимости доставки по зонам&nbsp;города или поставить фиксированную
							стоимость для всех заказов.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image1}
							src="./assets/delivery-feature-1_by.webp"
							alt={""}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
				{
					desktopContentWidth: 500,
					tabName: "Меню",
					desktopTitle: "Синхронизация позиций",
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Автоматическое обновление во&nbsp;всех сервисах. Актуальное&nbsp;меню с&nbsp;акциями и&nbsp;доступными
							позициями в&nbsp;приложении, агрегаторах и&nbsp;на&nbsp;сайте.
						</p>
					),
					tabletContent: (
						<p className={mediaContent.smallDescription}>
							Автоматическое обновление во&nbsp;всех сервисах. Актуальное меню с&nbsp;акциями и&nbsp;доступными
							позициями в&nbsp;приложении, агрегаторах и&nbsp;на&nbsp;сайте.
						</p>
					),
					mobileContent: (
						<p className={mediaContent.smallDescription}>
							Автоматическое обновление во всех&nbsp;сервисах. Актуальное меню с&nbsp;акциями и&nbsp;доступными
							позициями в&nbsp;приложении, агрегаторах и&nbsp;на&nbsp;сайте.
						</p>
					),
					mediaContent: (
						<>
							<StaticImage
								className={mediaContent.image3}
								src="./assets/delivery-feature-3_by.webp"
								alt={""}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		};
	}

	return {
		features: [
			{
				desktopContentWidth: 500,
				tabName: "Зоны доставки",
				desktopTitle: "Расчёт стоимости",
				mobileTitle: "Расчёт стоимости",
				desktopContent: (
					<p className={mediaContent.smallDescription}>
						Модуль поддерживает расчёт доставки по&nbsp;зонам города. Считай
						стоимость автоматически в&nbsp;зависимости от&nbsp;суммы
						заказа&nbsp;&mdash; данные подтягиваются из&nbsp;настроек
						в&nbsp;бэк-офисе.
						<br />
						Кассир будет выбирать, в&nbsp;какой район отвезти заказ, а&nbsp;гость сразу узнает полную
						стоимость с&nbsp;учётом доставки еды.
					</p>
				),
				mediaContent: (
					<StaticImage
						className={mediaContent.image1}
						src="./assets/delivery-feature-1.png"
						alt={""}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
				backdropContent: <div className={styles.backdrop} />,
			},
			// {
			//     desktopContentWidth: 500,
			//     tabName: 'Агрегаторы',
			//     desktopTitle: 'Проверенные партнёры',
			//     desktopContent:
			//         <p className={mediaContent.smallDescription}>
			//             Используй возможности агрегаторов для расширения зоны доставки, оптимизации расходов на логистику. Подключай курьеров в пиковые часы.
			//         </p>,
			//     mediaContent: (
			//         <StaticImage
			//             className={mediaContent.image2}
			//             src="./assets/delivery-feature-2.png"
			//             alt={''}
			//             objectFit={'contain'}
			//             placeholder={'blurred'}
			//             quality={90}
			//         />
			//     ),
			//     backdropContent: (
			//         <div className={styles.backdrop}/>
			//     ),
			// },
			{
				desktopContentWidth: 500,
				tabName: "Меню",
				desktopTitle: "Синхронизация позиций",
				desktopContent: (
					<p className={mediaContent.smallDescription}>
						Автоматическое обновление во&nbsp;всех сервисах не&nbsp;даёт возможности ошибиться с&nbsp;вводом
						данных. Онлайн-заказы попадают в&nbsp;программу автоматизации доставки еды вместе с&nbsp;заказами
						в&nbsp;зале. Ничего не&nbsp;пройдёт мимо кассы и&nbsp;в&nbsp;складском учёте будет полный порядок.
						< br/>
						< br/>
						Актуальное меню с&nbsp;акциями и&nbsp;доступными блюдами в&nbsp;приложении, агрегаторах и&nbsp;на&nbsp;сайте.
						Тестируй комбо-предложения, скидки и&nbsp;бонусы хоть каждый день. Анализируй результаты и&nbsp;оставляй только
						работающие механики.
					</p>
				),
				mediaContent: (
					<>
						<StaticImage
							className={mediaContent.image3}
							src="./assets/delivery-feature-3.png"
							alt={""}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					</>
				),
				backdropContent: <div className={styles.backdrop} />,
			},
		],
	} as {
		features: Array<FeatureGalleryItem>
	};
};

