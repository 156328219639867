import React from "react";

import { FeatureGalleryItem } from "../../../components/_V2/FeaturesGallery";
import { StaticImage } from "gatsby-plugin-image";
import { pagesLinks } from "../../../pages-data/_V2/common/links";

import mediaContent from "./mediaContent.module.scss";
import MobileAppBackdropIcon from "../../../assets/images/mobileFeatures/MobileAppBackdropIcon";
import backdropIconsStyles from "./backdropIconsStyles.module.scss";
import WebsiteBackdropIcon from "../../../assets/images/mobileFeatures/WebsiteBackdropIcon";
import { Locales } from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return {
			features: [
				{
					desktopContentWidth: 660,
					tabName: "Приложение",
					desktopTitle: "Принимай заказы \nчерез своё приложение",
					tabletTitle: "Принимай заказы \nчерез своё приложение",
					mobileTitle: "Принимай \nзаказы через \nсвоё \nприложение",
					desktopContent:
						"Запусти брендированное приложение — это быстро, без найма разработчиков. Гости смогут пользоваться бонусной программой, делать заказы на доставку.",
					detailLink: pagesLinks.indexWL,
					mediaContent: (
						<div className={mediaContent.image__container1}>
							<StaticImage
								className={mediaContent.image1}
								src="../../../assets/images/mobileFeatures/phone_kz.png"
								alt={"мобильное приложение для гостей"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: (
						<MobileAppBackdropIcon
							className={backdropIconsStyles.mobileAppBackdropIcon}
						/>
					),
				},
				{
					desktopContentWidth: 600,
					tabName: "Сайт",
					desktopTitle: "Запусти собственный\nсайт с доставкой",
					tabletTitle: "Запусти собственный\nсайт с доставкой",
					mobileTitle: "Запусти \nсобственный\nсайт \nс доставкой",
					desktopContent:
						"Получай больше заказов с помощью сайта своего заведения. Подключение доставки с расчетом стоимости по зонам, опция самовывоза, всегда актуальное меню и различные способы оплаты.",
					tabletContent: (
						<>
							Получай больше заказов с&nbsp;помощью сайта своего заведения. Подключение доставки с&nbsp;расчетом
							стоимости по зонам,&nbsp;опция самовывоза, всегда актуальное меню и&nbsp;различные способы оплаты.
						</>
					),
					mobileContent: (
						<>
							Подключение доставки с расчётом&nbsp;стоимости по&nbsp;зонам, опция самовывоза, всегда актуальное меню
							и&nbsp;разные способы оплаты.
						</>
					),
					detailLink: pagesLinks.indexWL,
					mediaContent: (
						<div className={mediaContent.image__container2}>
							<StaticImage
								className={mediaContent.image2}
								src="../../../assets/images/mobileFeatures/shop-feature_kz.webp"
								alt={"сайт с доставкой для ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: (
						<WebsiteBackdropIcon
							className={backdropIconsStyles.websiteBackdropIcon}
						/>
					),
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		};
	}

	if (locale === "kz-KZ") {
		return {
			features: [
				{
					desktopContentWidth: 660,
					tabName: "Қосымша",
					desktopTitle: "Тапсырыстарды өз\nқосымшаңыз арқылы қабылдаңыз",
					tabletTitle: "Тапсырыстарды өз\nқосымшаңыз арқылы қабылдаңыз",
					mobileTitle: "Тапсырыстарды өз\nқосымшаңыз арқылы\nқабылдаңыз",
					desktopContent:
						"Брендтелген қосымшаны іске қосыңыз — бұл тез, әзірлеушілерді жалдаусыз болады. Қонақтар бонустық бағдарламаны пайдаланып, жеткізуге тапсырыстарды жасай алады.",
					detailLink: pagesLinks.indexWL,
					mediaContent: (
						<div className={mediaContent.image__container1}>
							<StaticImage
								className={mediaContent.image1}
								src="../../../assets/images/mobileFeatures/phone_kz.png"
								alt={"мобильное приложение для гостей"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: (
						<MobileAppBackdropIcon
							className={backdropIconsStyles.mobileAppBackdropIcon}
						/>
					),
				},
				{
					desktopContentWidth: 600,
					tabName: "Сайт",
					desktopTitle: "Жеткізу мүмкіндігі\nбар сайтыңызды іске қосыңыз",
					tabletTitle: "Жеткізу мүмкіндігі\nбар сайтыңызды іске қосыңыз",
					mobileTitle: "Жеткізу мүмкіндігі\nбар сайтыңызды\nіске қосыңыз",
					desktopContent:
						"Мекемеңіздің сайты көмегімен көбірек тапсырыстар алыңыз. Аймақтар бойынша құнды есептеумен жеткізуді қосу, өзімен әкету опциясы, әрқашан өзекті мәзір және әртүрлі төлеу тәсілдері.",
					detailLink: pagesLinks.indexWL,
					mediaContent: (
						<div className={mediaContent.image__container2}>
							<StaticImage
								className={mediaContent.image2}
								src="../../../assets/images/mobileFeatures/shop-feature_kz.webp"
								alt={"сайт с доставкой для ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: (
						<WebsiteBackdropIcon
							className={backdropIconsStyles.websiteBackdropIcon}
						/>
					),
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		};
	}

	if (locale === "ru-BY") {
		return {
			features: [
				{
					desktopContentWidth: 660,
					tabName: "Приложение",
					desktopTitle: "Предлагай больше \nв собственном приложении",
					tabletTitle: "Предлагай больше \nв собственном приложении",
					mobileTitle: "Предлагай больше \nв собственном \nприложении",
					desktopContent: (
						<>
							Быстро, в&nbsp;фирменном стиле и&nbsp;без найма разработчиков.
							Клиенты смогут пользоваться бонусной программой и делать заказы на&nbsp;доставку.
						</>
					),
					tabletContent: (
						<>
							Быстро, в&nbsp;фирменном стиле и&nbsp;без найма разработчиков. Клиенты смогут пользоваться бонусной
							программой и делать&nbsp;заказы на&nbsp;доставку.
						</>
					),
					mobileContent: (
						<>
							Быстро, в&nbsp;фирменном стиле и <br />
							без найма разработчиков. <br />
							Клиенты смогут пользоваться <br />
							бонусной программой и&nbsp;делать <br />
							заказы на&nbsp;доставку.
						</>
					),
					detailLink: pagesLinks.indexWL,
					mediaContent: (
						<div className={mediaContent.image__container1}>
							<StaticImage
								className={mediaContent.image1}
								src="../../../assets/images/mobileFeatures/phone_by.png"
								alt={"мобильное приложение для гостей"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: (
						<MobileAppBackdropIcon
							className={backdropIconsStyles.mobileAppBackdropIcon}
						/>
					),
				},
				{
					desktopContentWidth: 600,
					tabName: "Сайт",
					desktopTitle: "А ещё больше — \nна полноценном сайте",
					tabletTitle: "А ещё больше — на полноценном сайте",
					mobileTitle: "А ещё больше — на полноцен\nном сайте",
					desktopContent: (
						<>
							Подключение доставки с&nbsp;расчётом стоимости по&nbsp;зонам, опция самовывоза,&nbsp;всегда актуальное
							меню
							и&nbsp;разные способы оплаты.
						</>
					),
					mobileContent: (
						<>
							Подключение доставки с <br />
							расчётом стоимости по&nbsp;зонам, <br />
							опция самовывоза, всегда <br />
							актуальное меню и&nbsp;разные <br />
							способы оплаты.
						</>
					),
					detailLink: pagesLinks.indexWL,
					mediaContent: (
						<div className={mediaContent.image__container2}>
							<StaticImage
								className={mediaContent.image2}
								src="../../../assets/images/mobileFeatures/shop-feature_by.webp"
								alt={"сайт с доставкой для ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: (
						<WebsiteBackdropIcon
							className={backdropIconsStyles.websiteBackdropIcon}
						/>
					),
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		};
	}

	return {
		features: [
			{
				desktopContentWidth: 660,
				tabName: "Приложение",
				desktopTitle: (
					<>
						Больше заказов
						<br />с приложением для гостей
					</>
				),
				tabletTitle: "Больше заказов с приложением для гостей",
				mobileTitle: "Больше заказов с приложением для гостей",
				desktopContent: (
					<>
						Собственное приложение доставки ресторана&nbsp;&mdash; не&nbsp;страница на&nbsp;площадке
						агрегатора. Всё в&nbsp;фирменном стиле компании, гости запоминают заведение и&nbsp;выбирают его
						снова. Клиенты могут пользоваться бонусной программой и&nbsp;делать заказы на&nbsp;доставку.
						Контактные данные и&nbsp;история заказов сохраняются в&nbsp;CRM-системе, база лояльных гостей
						растёт. Их&nbsp;можно сегментировать по&nbsp;активности и&nbsp;давать персональные предложения.
						< br />
						< br />
						Приложение&nbsp;&mdash; мобильный официант. Предложит акции и&nbsp;скидки, порекомендует соус
						к&nbsp;картошке, начислит бонусные баллы постоянному гостю.
					</>
				),
				tabletContent: (
					<>
						Собственное приложение доставки ресторана&nbsp;&mdash; не&nbsp;страница на&nbsp;площадке
						агрегатора. Всё в&nbsp;фирменном стиле компании, гости запоминают заведение и&nbsp;выбирают его
						снова. Клиенты могут пользоваться бонусной программой и&nbsp;делать заказы на&nbsp;доставку.
						Контактные данные и&nbsp;история заказов сохраняются в&nbsp;CRM-системе, база лояльных гостей
						растёт. Их&nbsp;можно сегментировать по&nbsp;активности и&nbsp;давать персональные предложения.
						< br />
						< br />
						Приложение&nbsp;&mdash; мобильный официант. Предложит акции и&nbsp;скидки, порекомендует соус
						к&nbsp;картошке, начислит бонусные баллы постоянному гостю.
					</>
				),
				mobileContent: (
					<>
						Собственное приложение доставки ресторана&nbsp;&mdash; не&nbsp;страница на&nbsp;площадке
						агрегатора. Всё в&nbsp;фирменном стиле компании, гости запоминают заведение и&nbsp;выбирают его
						снова. Клиенты могут пользоваться бонусной программой и&nbsp;делать заказы на&nbsp;доставку.
						Контактные данные и&nbsp;история заказов сохраняются в&nbsp;CRM-системе, база лояльных гостей
						растёт. Их&nbsp;можно сегментировать по&nbsp;активности и&nbsp;давать персональные предложения.
						< br />
						< br />
						Приложение&nbsp;&mdash; мобильный официант. Предложит акции и&nbsp;скидки, порекомендует соус
						к&nbsp;картошке, начислит бонусные баллы постоянному гостю.
					</>
				),
				detailLink: pagesLinks.indexWL,
				mediaContent: (
					<div className={mediaContent.image__container1}>
						<StaticImage
							className={mediaContent.image1}
							src="../../../assets/images/mobileFeatures/phone.png"
							alt={""}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					</div>
				),
				backdropContent: (
					<MobileAppBackdropIcon
						className={backdropIconsStyles.mobileAppBackdropIcon}
					/>
				),
			},
			{
				desktopContentWidth: 600,
				tabName: "Сайт",
				desktopTitle: (
					<>
						А ещё больше —<br />
						на полноценном сайте
					</>
				),
				tabletTitle: "А ещё больше — на полноценном сайте",
				mobileTitle: "А ещё больше — на полноценном сайте",
				desktopContent: (
					<>
						Сайт даёт новые возможности для развития предприятия. Клиент ищет в&nbsp;интернете доставку
						пиццы в&nbsp;своём районе и&nbsp;находит меню заведения. Хочет оформить заказ столика
						на&nbsp;вечер&nbsp;&mdash; быстро находит номер кафе.
						< br />
						< br />
						На&nbsp;сайте есть всё, что нужно знать для заказа: описания блюд с&nbsp;КБЖУ и&nbsp;фото, выбор
						топпингов, приборов и&nbsp;способа расчёта.
						< br />
						< br />
						Подключение доставки с&nbsp;расчётом стоимости по&nbsp;зонам, опция самовывоза, всегда актуальное
						меню. Разные способы оплаты в&nbsp;программном модуле доставки: онлайн, наличными, картой, бонусами.
					</>
				),
				tabletContent: (
					<>
						Сайт даёт новые возможности для развития предприятия. Клиент ищет в&nbsp;интернете доставку
						пиццы в&nbsp;своём районе и&nbsp;находит меню заведения. Хочет оформить заказ столика
						на&nbsp;вечер&nbsp;&mdash; быстро находит номер кафе.
						< br />
						< br />
						На&nbsp;сайте есть всё, что нужно знать для заказа: описания блюд с&nbsp;КБЖУ и&nbsp;фото, выбор
						топпингов, приборов и&nbsp;способа расчёта.
						< br />
						< br />
						Подключение доставки с&nbsp;расчётом стоимости по&nbsp;зонам, опция самовывоза, всегда актуальное
						меню. Разные способы оплаты в&nbsp;программном модуле доставки: онлайн, наличными, картой, бонусами.
					</>
				),
				mobileContent: (
					<>
						Сайт даёт новые возможности для развития предприятия. Клиент ищет в&nbsp;интернете доставку
						пиццы в&nbsp;своём районе и&nbsp;находит меню заведения. Хочет оформить заказ столика
						на&nbsp;вечер&nbsp;&mdash; быстро находит номер кафе.
						< br />
						< br />
						На&nbsp;сайте есть всё, что нужно знать для заказа: описания блюд с&nbsp;КБЖУ и&nbsp;фото, выбор
						топпингов, приборов и&nbsp;способа расчёта.
						< br />
						< br />
						Подключение доставки с&nbsp;расчётом стоимости по&nbsp;зонам, опция самовывоза, всегда актуальное
						меню. Разные способы оплаты в&nbsp;программном модуле доставки: онлайн, наличными, картой, бонусами.
					</>
				),
				detailLink: pagesLinks.indexWL,
				mediaContent: (
					<div className={mediaContent.image__container2}>
						<StaticImage
							className={mediaContent.image2}
							src="../../../assets/images/mobileFeatures/shop-feature.webp"
							alt={""}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					</div>
				),
				backdropContent: (
					<WebsiteBackdropIcon
						className={backdropIconsStyles.websiteBackdropIcon}
					/>
				),
			},
		],
	} as {
		features: Array<FeatureGalleryItem>
	};
};
