import { Locales } from "../../../localization/types";
import mediaContent from "../../intergations/DeliveryFeatures/mediaContent.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import styles from "../../intergations/DeliveryFeatures/styles.module.scss";
import { pagesLinks } from "../../../pages-data/_V2/common/links";
import { FeatureGalleryItem } from "../../../components/_V2/FeaturesGallery";
import React from "react";

export const getData = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return {
			features: [
				{
					desktopContentWidth: 600,
					tabName: "Яндекс Еда",
					desktopTitle: "Интеграция \nна любой вкус",
					tabletTitle: "Интеграция на любой вкус",
					mobileTitle: "Интеграция \nна любой вкус",
					desktopContent: (
						<p className={mediaContent.mediumDescription}>
							Приём заказов, оформленных через приложение и&nbsp;сайт агрегатора, на&nbsp;кассовом терминале. Доставка
							заказов курьерами сервиса&nbsp;или с помощью своих сотрудников. Автоматическое обновление меню при
							изменениях в&nbsp;бэк-офисе.
						</p>
					),
					tabletContent: (
						<p className={mediaContent.tabletDesk}>
							Приём заказов, оформленных через приложение и&nbsp;сайт агрегатора, на&nbsp;кассовом терминале. Доставка
							заказов курьерами сервиса или с&nbsp;помощью своих сотрудников. Автоматическое обновление меню при
							изменениях в&nbsp;бэк-офисе.
						</p>
					),
					mobileContent: (
						<p>
							Приём заказов, оформленных <br />
							через приложение и&nbsp;сайт <br />
							агрегатора, на&nbsp;кассовом <br />
							терминале. Доставка заказов <br />
							курьерами сервиса или <br />
							с&nbsp;помощью своих <br />
							сотрудников. Автоматическое <br />
							обновление меню при <br />
							изменениях в&nbsp;бэк-офисе.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/integrations-delivery-2.png"
							alt={"интеграция quick resto и яндекс еда"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
				{
					desktopContentWidth: 515,
					tabName: "Яндекс Доставка",
					desktopTitle: "Сотрудничай \nс сильнейшими",
					tabletTitle: "Сотрудничай с сильнейшими",
					mobileTitle: "Сотрудничай \nс сильнейшими",
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Незаменимый партнёр для доставки заказов в&nbsp;пиковые часы. Среднее время на&nbsp;поиск
							курьера&nbsp;&mdash; 2&nbsp;минуты, время ожидания курьера&nbsp;&mdash; 10&nbsp;минут. Управление напрямую
							с&nbsp;кассового терминала.
						</p>
					),
					tabletContent: (
						<p>
							Незаменимый партнёр для доставки заказов в&nbsp;пиковые часы. Среднее время на&nbsp;поиск
							курьера&nbsp;&mdash; 2 минуты,&nbsp;время ожидания курьера&nbsp;&mdash; 10&nbsp;минут. Управление напрямую
							с&nbsp;кассового терминала.
						</p>
					),
					mobileContent: (
						<p>
							Незаменимый партнёр для <br />
							доставки заказов в&nbsp;пиковые <br />
							часы. Среднее время на <br />
							поиск курьера&nbsp;&mdash; 2&nbsp;минуты, <br />
							время ожидания курьера&nbsp;&mdash; <br />
							10&nbsp;минут. Управление <br />
							напрямую с&nbsp;кассового <br />
							терминала.
						</p>
					),
					mediaContent: (
						<>
							<StaticImage
								className={mediaContent.image}
								src="./assets/integrations-delivery-3.png"
								alt={"интеграция quick resto и яндекс доставка"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		};
	}

	if (locale === "kz-KZ") {
		return {
			features: [
				{
					desktopContentWidth: 600,
					tabName: "Яндекс Еда",
					desktopTitle: "Кез келген талғамға\nсай келетін интеграция",
					desktopContent: (
						<p className={mediaContent.mediumDescription}>
							Агрегатордың қосымшасы мен сайты арқылы ресімделген тапсырыстарды кассалық терминалда қабылдау, тапсырыстарды сервис курьерлері арқылы немесе
							өз қызметкерлері көмегімен жеткізу, бэк-офисте өзгерістер болған кезде мәзірді автоматты түрде жаңарту.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/integrations-delivery-2.png"
							alt={"интеграция quick resto и яндекс еда"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
				{
					desktopContentWidth: 515,
					tabName: "Яндекс Доставка",
					desktopTitle: "Ең күштілермен ынтымақтасыңыз",
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Қарбалас сәтте тапсырыстарды жеткізу үшін таптырмайтын серіктес. Курьерді іздеуге орташа
							уақыт — 2 минут, курьерді күту уақыты — 10 минут. Кассалық терминалдан тікелей басқару.
						</p>
					),
					mediaContent: (
						<>
							<StaticImage
								className={mediaContent.image}
								src="./assets/integrations-delivery-3.png"
								alt={"интеграция quick resto и яндекс доставка"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		};
	}
};
