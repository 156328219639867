import { Locales } from "../../../localization/types";
import styles from "../../../pagesSections/delivery/ProductPresentation/styles.module.scss"

export const getAdvantages = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return [
			{
				title: "Запуск за 1 день",
				description:
					"Создай готовый сайт и&nbsp;приложение без сторонних дизайнеров и&nbsp;программистов. Начни доставлять блюда уже сегодня.",
			},
			{
				title: "Легко масштабироваться",
				description:
					"Гибкая работа со службой доставки: подключай дополнительных курьеров в пиковые часы, если свои  не справляются.",
			},
			{
				title: "Все типы оплат",
				description:
					"Гость может выбрать подходящий способ: наличные, банковская карта, бонусные баллы или смешанная оплата.",
				descriptionClass: styles.adv_title_kz
			},
		]
	}

	if (locale === 'kz-KZ') {
		return [
			{
				title: "1 күнде іске қосу",
				description:
					"Бөгде дизайнерлер мен бағдарламашыларсыз дайын сайтты және қосымшаны жасаңыз. Тағамдарды тіпті қазір жеткізе бастаңыз.",
			},
			{
				title: "Масштабтау оңай",
				description:
					"Жеткізу қызметімен икемді жұмыс істеу: егер өзіңіздің курьерлеріңіздің шамасы келмей жатса, қарбаалас уақыта қосымша курьерлерді іске қосыңыз.",
			},
			{
				title: "Барлық төлемдер түрлері",
				description:
					"Қонақы қолайлы тәсілді таңдай алады: қолма-қол ақша, банк картасы, бонустық баллдар немесе аралас төлем.",
				descriptionClass: styles.adv_title_kz
			},
		]
	}

	if (locale === 'ru-BY') {
		return [
			{
				title: "Запуск за 1 день",
				description:
					"Создай готовый сайт и приложение без сторонних дизайнеров и программистов. Начни доставлять блюда уже сегодня.",
			},
			{
				title: "Легко масштабироваться",
				description:
					"Гибкая работа со службой доставки: подключай дополнительных курьеров в пиковые часы, если свои  не справляются.",
			},
			{
				title: "Все типы оплат",
				description:
					"Гость может выбрать подходящий способ: наличные, банковская карта, бонусные баллы или смешанная оплата.",
				descriptionClass: styles.adv_title_kz
			},
		]
	}

	return [
		{
			title: "Запуск за 1 день",
			description:
				"Создай готовый сайт и приложение без сторонних дизайнеров и программистов. Начни доставлять блюда уже сегодня.",
		},
		{
			title: "Легко масштабироваться",
			description:
				"Гибкая работа со службой доставки: подключай дополнительных курьеров в пиковые часы, если свои  не справляются.",
		},
		{
			title: "Все типы оплат",
			description:
				"Гость может выбрать подходящий способ: наличные, банковская карта, бонусные баллы или смешанная оплата.",
		},
	]
}
