import * as React from "react"

const WebsiteBackdropIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 428 514"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M428 202.487C428 175.91 422.465 149.593 411.71 125.039C400.956 100.485 385.193 78.1749 365.321 59.3822C345.449 40.5895 321.858 25.6823 295.894 15.5117C269.931 5.34116 242.103 0.106445 214 0.106445C185.897 0.106445 158.069 5.34117 132.106 15.5117C106.142 25.6823 82.5509 40.5895 62.6791 59.3822C42.8074 78.1749 27.0443 100.485 16.2898 125.039C5.53527 149.593 -2.45683e-06 175.91 0 202.487H0.463669C0.536332 205.803 1.91083 209.099 4.58717 211.63L166.247 364.512C163.588 365.141 161.211 365.963 158.995 367.035C150.664 371.098 143.895 377.573 139.658 385.534C134.813 394.577 134.813 406.427 134.813 430.087L134.986 445.82C134.986 469.48 134.986 481.33 139.831 490.373C144.068 498.334 150.837 504.809 159.168 508.872C168.642 513.455 181.057 513.455 205.888 513.455H222.327C247.115 513.455 259.531 513.455 269.005 508.872C277.348 504.812 284.131 498.337 288.385 490.373C293.187 481.33 293.187 469.48 293.187 445.82V430.087C293.187 406.427 293.187 394.577 288.385 385.534C284.131 377.57 277.348 371.095 269.005 367.035C266.789 365.963 264.412 365.142 261.753 364.512L423.413 211.63C426.089 209.099 427.464 205.803 427.537 202.487H428ZM393.232 202.487L244.926 342.74L303.682 202.487H393.232ZM154.626 202.487L214 344.213L273.374 202.487H154.626ZM124.318 202.487L183.074 342.74L34.7679 202.487H124.318ZM179.043 405.088C179.043 397.729 185.351 391.764 193.132 391.764H234.867C242.648 391.764 248.956 397.729 248.956 405.088C248.956 412.447 242.648 418.412 234.867 418.412H193.132C185.351 418.412 179.043 412.447 179.043 405.088Z"
			fill="#E4F7F2"
		/>
	</svg>
)

export default WebsiteBackdropIcon
