import * as React from "react"

const MobileAppBackdropIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 694 660"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M346.833 0L453.935 217.008L693.419 251.807L520.124 420.725L561.034 659.239L346.833 546.629L132.635 659.239L173.542 420.725L0.250977 251.807L239.734 217.008L346.833 0Z"
			fill="#E4F7F2"
		/>
	</svg>
)

export default MobileAppBackdropIcon
