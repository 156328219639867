import React from "react";
import { SolidTabsProps } from "../../../components/_V2/SolidTabs";
import styles from "./styles.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import classNames from "classnames";
import { Locales } from "../../../localization/types";

export const getData = (locale: Locales): SolidTabsProps => {
	if (locale === "ru-KZ") {
		return {
			items: [
				{
					tabName: "Экосистема",
					desktopTitle: "Все заказы в одной системе",
					mobileTitle: "Все заказы в одной системе",
					tabletTitle: "Все заказы в одной системе",
					desktopContent: (
						<>
							Заказы из&nbsp;агрегатов, с&nbsp;сайта и&nbsp;приложения отображаются в&nbsp;едином интерфейсе. <br />
							Обрабатывай заказы с&nbsp;того&nbsp;же терминала, который используется в&nbsp;зале.
						</>
					),
					tabletContent: (
						<>
							Заказы из&nbsp;агрегатов, с&nbsp;сайта и&nbsp;приложения отображаются в&nbsp;едином интерфейсе. <br />
							Обрабатывай заказы с&nbsp;того&nbsp;же терминала, который используется в&nbsp;зале.
						</>
					),
					mobileContent: (
						<>
							Заказы из&nbsp;агрегатов, с&nbsp;сайта и приложения&nbsp;отображаются в едином&nbsp;интерфейсе. <br />
							Обрабатывай заказы с&nbsp;того&nbsp;же терминала, который используется в&nbsp;зале.
						</>
					),
					mediaContent: (
						<div className={styles.mediaContainer}>
							<StaticImage
								className={classNames(styles.mediaImage)}
								src={"./assets/delivery-feature-1_kz.webp"}
								alt={"программа учета для ресторана"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
				{
					tabName: "Способы доставки",
					desktopTitle: "Управляй доставкой",
					mobileTitle: "Управляй доставкой",
					tabletTitle: "Управляй доставкой",
					titleClass: styles.title__kz,
					desktopContent: (
						<>
							Распредели доставку между курьерами агрегаторов и&nbsp;своими сотрудниками. <br />
							Это поможет в&nbsp;пиковые часы заказов. Управление происходит прямо с&nbsp;кассового терминала.
						</>
					),
					tabletContent: (
						<>
							Распредели доставку между курьерами агрегаторов и&nbsp;своими сотрудниками. <br />
							Это поможет в&nbsp;пиковые часы заказов. Управление происходит прямо с&nbsp;кассового терминала.
						</>
					),
					mobileContent: (
						<>
							Распредели доставку между курьерами агрегаторов и&nbsp;своими сотрудниками. <br />
							Это поможет в&nbsp;пиковые часы заказов. Управление происходит прямо с&nbsp;кассового терминала.
						</>
					),
					mediaContent: (
						<div className={styles.mediaContainer}>
							<StaticImage
								className={classNames(styles.mediaImage, styles.mediaImage2)}
								src={"./assets/delivery-feature-2_kz.webp"}
								alt={""}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
				{
					tabName: "Авторизация гостя",
					desktopTitle: "Знай своих гостей",
					mobileTitle: "Знай своих гостей",
					tabletTitle: "Знай своих гостей",
					desktopContent: (
						<>
							Введи имя или телефон&nbsp;&mdash; карточка с&nbsp;адресом доставки отобразится на&nbsp;экране. <br />
							Если гость ещё ничего не&nbsp;заказывал, карточку можно быстро создать.
						</>
					),
					tabletContent: (
						<>
							Введи имя или телефон&nbsp;&mdash; карточка с&nbsp;адресом доставки отобразится на&nbsp;экране. <br />
							Если гость ещё ничего не&nbsp;заказывал, карточку можно быстро создать.
						</>
					),
					mobileContent: (
						<>
							Введи имя или телефон&nbsp;&mdash; карточка с&nbsp;адресом доставки отобразится на&nbsp;экране. <br />
							Если гость ещё ничего не заказывал,&nbsp;карточку можно быстро создать.
						</>
					),
					mediaContent: (
						<div className={styles.mediaContainer}>
							<StaticImage
								className={classNames(styles.mediaImage, styles.mediaImage3__kz)}
								src={"./assets/delivery-feature-3_kz.webp"}
								alt={""}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
			],
		};
	}

	if (locale === "kz-KZ") {
		return {
			items: [
				{
					tabName: "Экожүйе",
					desktopTitle: "Барлық тапсырыстар бір интерфейсте",
					desktopContent: (
						<>
							Агрегаттардан, сайттан және қосымшадан келіп түсетін тапсырыстар бірыңғай интерфейсте көрсетіледі. <br />
							Залда пайдаланылып жатқан терминалдан тапсырыстарды өңдеңіз.
						</>
					),
					tabletContent: (
						<>
							Агрегаттардан, сайттан және қосымшадан келіп түсетін тапсырыстар бірыңғай интерфейсте көрсетіледі.
							Залда пайдаланылып жатқан терминалдан тапсырыстарды өңдеңіз.
						</>
					),
					mobileContent: (
						<>
							Агрегаттардан, сайттан және қосымшадан келіп түсетін тапсырыстар бірыңғай интерфейсте көрсетіледі.
							Залда пайдаланылып жатқан терминалдан тапсырыстарды өңдеңіз.
						</>
					),
					mediaContent: (
						<div className={styles.mediaContainer}>
							<StaticImage
								className={classNames(styles.mediaImage)}
								src={"./assets/delivery-feature-1_kz.webp"}
								alt={"программа учета для ресторана"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
				{
					tabName: "Жеткізу тәсілдері",
					desktopTitle: "Жеткізуді басқарыңыз",
					titleClass: styles.title__kz,
					desktopContent: (
						<>
							Агрегаторлар курьерлері мен өзіңіздің қызметкерлеріңіз арасында жеткізуді бөліңіз. <br />
							Бұл тапсырыстардың қарбалас сағаттарында көмектеседі. Басқару тікелей кассалық терминалдан болады.
						</>
					),
					mediaContent: (
						<div className={styles.mediaContainer}>
							<StaticImage
								className={classNames(styles.mediaImage, styles.mediaImage2)}
								src={"./assets/delivery-feature-2_kz.webp"}
								alt={""}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
				{
					tabName: "Қонақтың авторизациясы",
					desktopTitle: "Өз қонақтарыңызды біліңіз",
					desktopContent: (
						<>
							Атын немесе телефонын енгізіңіз - жеткізу мекенжайы бар карточка экранда көрсетіледі. <br />
							Егер қонақ әлі ештеңеге тапсырыс бермесе, карточканы жылдам құруға болады.
						</>
					),
					mediaContent: (
						<div className={styles.mediaContainer}>
							<StaticImage
								className={classNames(styles.mediaImage, styles.mediaImage3__kz)}
								src={"./assets/delivery-feature-3_kz.webp"}
								alt={""}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
			],
		};
	}

	if (locale === "ru-BY") {
		return {
			items: [
				{
					tabName: "Экосистема",
					desktopTitle: "Собирай все заказы в одном месте",
					tabletTitle: "Собирай все заказы в одном месте",
					mobileTitle: "Собирай \nвсе заказы \nв одном месте",
					desktopContent: (
						<>
							Заказы из&nbsp;агрегатов, с&nbsp;сайта и&nbsp;приложения отображаются в&nbsp;едином интерфейсе. <br />
							Обрабатывай заказы с&nbsp;того&nbsp;же терминала, который используется в&nbsp;зале.
						</>
					),
					tabletContent: (
						<>
							Заказы из&nbsp;агрегатов, с&nbsp;сайта и&nbsp;приложения отображаются в&nbsp;едином интерфейсе. <br />
							Обрабатывай заказы с&nbsp;того&nbsp;же терминала, который используется в&nbsp;зале.
						</>
					),
					mobileContent: (
						<>
							Заказы из&nbsp;агрегатов, с&nbsp;сайта и приложения&nbsp;отображаются в едином&nbsp;интерфейсе. <br />
							Обрабатывай заказы с&nbsp;того&nbsp;же терминала, который используется в&nbsp;зале.
						</>
					),
					mediaContent: (
						<div className={styles.mediaContainer}>
							<StaticImage
								className={classNames(styles.mediaImage)}
								src={"./assets/delivery-feature-1_by.webp"}
								alt={"программа учета для ресторана"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
				{
					tabName: "Способы доставки",
					desktopTitle: "Управляй доставкой",
					mobileTitle: "Управляй доставкой",
					tabletTitle: "Управляй доставкой",
					desktopContent: (
						<>
							Распредели доставку между курьерами агрегаторов и&nbsp;своими сотрудниками. <br />
							Это поможет в&nbsp;пиковые часы заказов. Управление происходит прямо с&nbsp;кассового терминала.
						</>
					),
					tabletContent: (
						<>
							Распредели доставку между курьерами агрегаторов и&nbsp;своими сотрудниками. <br />
							Это поможет в&nbsp;пиковые часы заказов. Управление происходит прямо с&nbsp;кассового терминала.
						</>
					),
					mobileContent: (
						<>
							Распредели доставку между курьерами агрегаторов и&nbsp;своими сотрудниками. <br />
							Это поможет в&nbsp;пиковые часы заказов. Управление происходит прямо с&nbsp;кассового терминала.
						</>
					),
					mediaContent: (
						<div className={styles.mediaContainer}>
							<StaticImage
								className={classNames(styles.mediaImage, styles.mediaImage2)}
								src={"./assets/delivery-feature-2_by.webp"}
								alt={""}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
				{
					tabName: "Авторизация гостя",
					desktopTitle: "Знай каждого гостя",
					tabletTitle: "Знай каждого гостя",
					mobileTitle: "Знай \nкаждого гостя",
					desktopContent: (
						<>
							Введи имя или телефон&nbsp;&mdash; карточка с&nbsp;адресом доставки отобразится на&nbsp;экране. <br />
							Если гость ещё ничего не&nbsp;заказывал, карточку можно быстро создать.
						</>
					),
					tabletContent: (
						<>
							Введи имя или телефон&nbsp;&mdash; карточка с&nbsp;адресом доставки отобразится на&nbsp;экране. <br />
							Если гость ещё ничего не&nbsp;заказывал, карточку можно быстро создать.
						</>
					),
					mobileContent: (
						<>
							Введи имя или телефон&nbsp;&mdash; карточка с&nbsp;адресом доставки отобразится на&nbsp;экране. <br />
							Если гость ещё ничего не заказывал,&nbsp;карточку можно быстро создать.
						</>
					),
					mediaContent: (
						<div className={styles.mediaContainer}>
							<StaticImage
								className={classNames(styles.mediaImage, styles.mediaImage3__kz)}
								src={"./assets/delivery-feature-3_by.webp"}
								alt={""}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
				},
			],
		};
	}

	return {
		items: [
			{
				tabName: "Экосистема",
				desktopTitle: "Все заказы в одной системе",
				mobileTitle: "Все заказы в одной системе",
				tabletTitle: "Все заказы в одной системе",
				desktopContent: (
					<>
						С&nbsp;программным модулем доставки не&nbsp;нужно устанавливать кучу приложений.
						Заказы из&nbsp;агрегаторов, с&nbsp;сайта и&nbsp;мобильного приложения для гостей отображаются
						в&nbsp;едином интерфейсе. Обрабатывай заказы с&nbsp;того&nbsp;же терминала, который используется
						в&nbsp;зале.
					</>
				),
				tabletContent: (
					<>
						С&nbsp;программным модулем доставки не&nbsp;нужно устанавливать кучу приложений.
						Заказы из&nbsp;агрегаторов, с&nbsp;сайта и&nbsp;мобильного приложения для гостей отображаются
						в&nbsp;едином интерфейсе. Обрабатывай заказы с&nbsp;того&nbsp;же терминала, который используется
						в&nbsp;зале.
					</>
				),
				mobileContent: (
					<>
						С&nbsp;программным модулем доставки не&nbsp;нужно устанавливать кучу приложений.
						Заказы из&nbsp;агрегаторов, с&nbsp;сайта и&nbsp;мобильного приложения для гостей отображаются
						в&nbsp;едином интерфейсе. Обрабатывай заказы с&nbsp;того&nbsp;же терминала, который используется
						в&nbsp;зале.
					</>
				),
				mediaContent: (
					<div className={styles.mediaContainer}>
						<StaticImage
							className={classNames(styles.mediaImage)}
							src={"./assets/delivery-feature-1.png"}
							alt={"программа учета для ресторана"}
							objectFit="contain"
							breakpoints={[223, 642, 690]}
							quality={90}
						/>
					</div>
				),
				backdropContent: <div className={styles.backdrop}></div>,
			},
			{
				tabName: "Процессы",
				desktopTitle: "Работает как часы",
				mobileTitle: "Работает как часы",
				tabletTitle: "Работает как часы",
				desktopContent:
					"Автоматизация превращает бизнес-процессы в слаженный механизм. Заказ из мобильного приложения приходит на кассовый терминал. Повара видят новые заказы на собственном экране на кухне. Программа подаст повару звуковой сигнал и посчитает затраченное время приготовления.",
				tabletContent:
					"Автоматизация превращает бизнес-процессы в слаженный механизм. Заказ из мобильного приложения приходит на кассовый терминал. Повара видят новые заказы на собственном экране на кухне. Программа подаст повару звуковой сигнал и посчитает затраченное время приготовления.",
				mobileContent:
					"Автоматизация превращает бизнес-процессы в слаженный механизм. Заказ из мобильного приложения приходит на кассовый терминал. Повара видят новые заказы на собственном экране на кухне. Программа подаст повару звуковой сигнал и посчитает затраченное время приготовления.",
				mediaContent: (
					<div className={styles.mediaContainer}>
						<StaticImage
							className={classNames(styles.mediaImage, styles.mediaImage2)}
							src={"./assets/delivery-feature-2.png"}
							alt={""}
							objectFit="contain"
							breakpoints={[223, 642, 690]}
							quality={90}
						/>
					</div>
				),
				backdropContent: <div className={styles.backdrop}></div>,
			},
			{
				tabName: "Сборка заказа",
				desktopTitle: "Чек сборщика",
				mobileTitle: "Чек сборщика",
				tabletTitle: "Чек сборщика",
				desktopContent:
					"Для сборщика заказов печатается специальный чек. Сотрудник сразу понимает, что и где забрать, и не тратит лишнее время. А довольный гость быстро получает заказ и оставляет хороший отзыв о скорости обслуживания.",
				tabletContent:
					"Для сборщика заказов печатается специальный чек. Сотрудник сразу понимает, что и где забрать, и не тратит лишнее время. А довольный гость быстро получает заказ и оставляет хороший отзыв о скорости обслуживания.",
				mobileContent:
					"Для сборщика заказов печатается специальный чек. Сотрудник сразу понимает, что и где забрать, и не тратит лишнее время. А довольный гость быстро получает заказ и оставляет хороший отзыв о скорости обслуживания.",
				mediaContent: (
					<div className={styles.mediaContainer}>
						<StaticImage
							className={classNames(styles.mediaImage, styles.mediaImage2)}
							src={"./assets/delivery-feature-2.png"}
							alt={""}
							objectFit="contain"
							breakpoints={[223, 642, 690]}
							quality={90}
						/>
					</div>
				),
				backdropContent: <div className={styles.backdrop}></div>,
			},
			{
				tabName: "Способы доставки",
				desktopTitle: "Удобно управлять",
				mobileTitle: "Удобно управлять",
				tabletTitle: "Удобно управлять",
				desktopContent:(
					<>
						Распредели доставку еды между курьерами агрегаторов и&nbsp;своими сотрудниками. Это поможет
						в&nbsp;пиковые часы заказов. Управление происходит прямо с&nbsp;кассового терминала.
						< br/>
						Если заказ просрочен, кассовый терминал сообщит в&nbsp;уведомлении.
					</>
				),
				tabletContent:(
					<>
						Распредели доставку еды между курьерами агрегаторов и&nbsp;своими сотрудниками. Это поможет
						в&nbsp;пиковые часы заказов. Управление происходит прямо с&nbsp;кассового терминала.
						< br/>
						Если заказ просрочен, кассовый терминал сообщит в&nbsp;уведомлении.
					</>
				),
				mobileContent:(
					<>
						Распредели доставку еды между курьерами агрегаторов и&nbsp;своими сотрудниками. Это поможет
						в&nbsp;пиковые часы заказов. Управление происходит прямо с&nbsp;кассового терминала.
						< br/>
						Если заказ просрочен, кассовый терминал сообщит в&nbsp;уведомлении.
					</>
				),
				mediaContent: (
					<div className={styles.mediaContainer}>
						<StaticImage
							className={classNames(styles.mediaImage, styles.mediaImage2)}
							src={"./assets/delivery-feature-2.png"}
							alt={""}
							objectFit="contain"
							breakpoints={[223, 642, 690]}
							quality={90}
						/>
					</div>
				),
				backdropContent: <div className={styles.backdrop}></div>,
			},
			{
				tabName: "Авторизация гостя",
				desktopTitle: "Гостю приятно, когда его узнают",
				mobileTitle: "Гостю приятно, когда его узнают",
				tabletTitle: "Гостю приятно, когда его узнают",
				desktopContent:(
					<>
						Введи имя или телефон&nbsp;&mdash; карточка с&nbsp;адресом доставки отобразится на&nbsp;экране.
						Если гость ещё ничего не&nbsp;заказывал, карточку можно быстро создать.
						< br/>
						< br/>
						Гость видит акции и&nbsp;статусы заказа в&nbsp;личном кабинете: оплачен, отправлен на&nbsp;кухню,
						готов. А&nbsp;потом можно оставить отзыв или повторить свой заказ.
					</>
				),
				tabletContent:(
					<>
						Введи имя или телефон&nbsp;&mdash; карточка с&nbsp;адресом доставки отобразится на&nbsp;экране.
						Если гость ещё ничего не&nbsp;заказывал, карточку можно быстро создать.
						< br/>
						< br/>
						Гость видит акции и&nbsp;статусы заказа в&nbsp;личном кабинете: оплачен, отправлен на&nbsp;кухню,
						готов. А&nbsp;потом можно оставить отзыв или повторить свой заказ.
					</>
				),
				mobileContent:(
					<>
						Введи имя или телефон&nbsp;&mdash; карточка с&nbsp;адресом доставки отобразится на&nbsp;экране.
						Если гость ещё ничего не&nbsp;заказывал, карточку можно быстро создать.
						< br/>
						< br/>
						Гость видит акции и&nbsp;статусы заказа в&nbsp;личном кабинете: оплачен, отправлен на&nbsp;кухню,
						готов. А&nbsp;потом можно оставить отзыв или повторить свой заказ.
					</>
				),
				mediaContent: (
					<div className={styles.mediaContainer}>
						<StaticImage
							className={classNames(styles.mediaImage, styles.mediaImage3)}
							src={"./assets/delivery-feature-3.png"}
							alt={""}
							objectFit="contain"
							breakpoints={[223, 642, 690]}
							quality={90}
						/>
					</div>
				),
				backdropContent: <div className={styles.backdrop}></div>,
			},
		],
	};
};
