import React from 'react'

export const getSeoText = () => (
  <div>
    <p>Доставка по своим правилам</p>

    Автоматизация доставки еды позволит масштабировать бизнес. Привлекайте заказы через собственный сайт
    заведения или приложение. Подключайтесь к агрегаторам Яндекс.Еда и Delivery Club и расширяйте базу клиентов.
    Модуль доставки освобождает от установки множества приложений. Заказы из агрегаторов, с сайта и мобильного
    приложения для гостей отображаются в едином интерфейсе. Обрабатывай заказы с того же терминала, который
    используется в зале. Автоматизация превращает бизнес-процессы в слаженный механизм. Заказ из мобильного
    приложения приходит на кассовый терминал. Повара видят новые заказы на собственном экране на кухне.
    Программа подаст повару звуковой сигнал и посчитает затраченное время приготовления. Для сборщика заказов
    печатается специальный чек. Сотрудник сразу понимает, что и где забрать, и не тратит лишнее время. А
    довольный гость быстро получает заказ и оставляет хороший отзыв о скорости обслуживания. Распредели доставку
    еды между курьерами агрегаторов и своими сотрудниками. Это поможет в пиковые часы заказов. Управление
    происходит прямо с кассового терминала.
    Если заказ просрочен, кассовый терминал сообщит в уведомлении. Введите имя или телефон клента — карточка с
    адресом доставки отобразится на экране. Если гость ещё ничего не заказывал, карточку можно быстро создать.
    Гость видит акции и статусы заказа в личном кабинете: оплачен, отправлен на кухню, готов. А потом можно
    оставить отзыв или повторить свой заказ.
    <p>Модуль расчета доставки</p>

    Модуль расчета доставки поддерживает расчёт по зонам города. Вы можете считать стоимость автоматически в
    зависимости от суммы заказа — данные подтягиваются из настроек в бэк-офисе. Кассир будет выбирать, в какой район
    отвезти заказ, а гость сразу узнает полную стоимость с учётом доставки еды. Автоматическое обновление во всех
    сервисах не даёт возможности ошибиться с вводом данных. Онлайн-заказы попадают в программу автоматизации
    доставки еды вместе с заказами в зале. Ничего не пройдёт мимо кассы, и в складском учёте будет полный порядок.
    С Quick Resto гость также получает актуальное меню с акциями и доступными блюдами в приложении, агрегаторах и на
    сайте. Собственное приложение доставки ресторана — не страница на площадке агрегатора. Всё в фирменном стиле
    компании, гости запоминают заведение и выбирают его снова. Гости могут пользоваться бонусной программой и делать
    заказы на доставку. Контактные данные и история заказов сохраняются в CRM-системе, гостей можно сегментировать
    по активности и давать персональные предложения.
    Приложение — мобильный официант. Предложит акции и скидки, порекомендует соус к картошке, начислит бонусные
    баллы постоянному гостю. Сайт даёт новые возможности для развития предприятия. Если гость ищет в интернете
    доставку пиццы в своём районе, то легко находит меню заведения. Если хочет оформить заказ столика на вечер —
    быстро находит номер ресторана.
    На сайте есть всё, что нужно знать для заказа: описания блюд с КБЖУ и фото, выбор топпингов, приборов и способа
    расчёта.

    <p>Модули интеграции доставки</p>

    Интеграция программы для автоматизации доставки еды с агрегатором открывает доступ к новой аудитории. Можно
    привлечь гостей из других районов города, куда неудобно отвозить заказы курьерам кафе.
    Информация обо всех акциях и скидках транслируется пользователям агрегатора. Меню автоматически обновляется при
    изменениях в бэк-офисе — в заказ не попадёт блюдо из стоп-листа, зато быстро попадут новинки. Если в заведении
    закончились продукты для десерта, он сразу попадёт в стоп-лист, а значит, клиент не успеет оставить заявку.
    Незаменимый партнёр для помощи с доставкой заказов в пиковые часы. Среднее время на поиск курьера — 2 минуты,
    время ожидания курьера — 10 минут. Управление напрямую с кассового терминала.

    <p>Доставка и оплата: модуль</p>

    С Quick Resto вы моежет подключить доставку с расчётом стоимости по зонам, а также настроить опцию самовывоза.
    Гостям также доступны разные способы оплаты в программном модуле доставки: онлайн, наличными, картой, бонусами.

    <p>Дополнительные возможности Quick Resto</p>

    Складской учет. Система автоматически списывает продукты со склада, обеспечивая точный учет и минимизацию
    потерь.
    Интернет-магазин. Полностью интегрированный сайт для оформления заказов на доставку.
    Автоматизация процессов. Вся информация обновляется в режиме реального времени, что исключает ошибки и задержки.
    Контроль качества. Отзывы и оценки клиентов помогают постоянно улучшать сервис.

    <p>Преимущества для владельцев ресторанов</p>

    Простая настройка. Все этапы автоматизации доступны в одном интерфейсе, что упрощает управление процессами.
    Расширение аудитории. Интеграция с популярными агрегаторами позволяет привлечь новых клиентов из разных районов.
    Повышение доходов. Эффективная автоматизация доставки из ресторана помогает увеличить количество заказов и
    средний чек.
    Улучшение сервиса. Быстрая и точная доставка способствует повышению лояльности клиентов и улучшению репутации
    заведения.

    Quick Resto предлагает комплексное решение для автоматизации доставки еды, которое подходит для ресторанов
    любого масштаба. Улучшайте качество обслуживания, расширяйте клиентскую базу и увеличивайте доходы с помощью
    автоматизации Quick Resto.

  </div>
)
