import React from "react"
import SolidTabs, { SOLID_TABS_THEMES } from "../../../components/_V2/SolidTabs"
import { getData } from "./data"
import { useLocalizationContext } from "../../../localization/useLocalizationContext";

interface Props {
	className?: string
}

export default function CRMFeatures(props: Props) {
	const localizationContext = useLocalizationContext()

	return (
		<SolidTabs
			key={localizationContext.locale}
			className={props.className}
			items={getData(localizationContext.locale).items}
			theme={SOLID_TABS_THEMES.ORANGE}
			transformOnAdaptive={true}
		/>
	)
}
